<template>
	<div class="layout-page">
		<div class="config-notifications">
			{{ $t('notification.config') }}
		</div>
		<div class="config-description">
			{{ $t('notification.description') }}
		</div>
		<div class="notifications-switches">
			<div class="switch-container" v-for="notification in notifications" :key="notification.id">
				<div class="text">
					{{ $t(notification.name) }}
				</div>
				<Switcher v-model="notification.status"></Switcher>
			</div>
		</div>
		<div class="save-changes" @click="saveChanges()">
			{{ $t('notification.save') }}
		</div>
	</div>
</template>

<script>
import Switcher from '../components/ui/Switcher.vue'
export default {
	components: { Switcher },
	name: 'Notifications',
	data() {
		return {}
	},
	computed: {
		notifications() {
			return this.$store.getters['notifications/getNotifications']()
		}
	},
	methods: {
		load() {
			this.$store.dispatch('notifications/loadNotifications').then(function () {})
		},
		saveChanges() {
			var self = this
			this.$overlay.show()
			var notifications = { ...this.notifications }
			this.$store.dispatch('notifications/saveChanges', notifications).then(function () {
				self.load()
				self.$overlay.hide()
				self.$snackbar.success({
					message: self.$t('snackbar.add_successfully'),
					customClass: 'success',
					duration: 3000
				})
			})
		}
	},
	created() {
		this.load()
	}
}
</script>

<style lang="scss">
.layout-page {
	.config-notifications {
		color: #222222;
		font-family: Speedee-bd;
		font-size: 22px;
		padding-bottom: 16px;
		font-family: Speedee-bd;
		font-size: 24px;
		text-transform: uppercase;
		margin-top: 40px;
	}
	.config-description {
		color: #adadad;
		font-family: Speedee-lt;
		font-size: 18px;
		padding-bottom: 30px;
		width: max-content;
	}
	.notifications-switches {
		display: flex;
		flex-direction: column;
		padding-bottom: 50px;
		.text {
			color: #222222;
			font-family: Speedee-lt;
			font-size: 16px;
			height: 60px;
			display: flex;
			align-items: center;
			width: 300px;
		}

		.switch-container {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			height: 60px;
		}
	}
	.save-changes {
		background-color: $yellow;
		width: fit-content;
		padding: 8px 20px;
		height: fit-content;
		font-family: Speedee-lt;
		font-weight: bold;
		font-size: 16px;
		border-radius: 4px;
		cursor: pointer;
	}
}
</style>
