<template>
    <div class="switch-container">
        <label class="switcher">
            <input type="checkbox" :checked="value == 0 ? false : true" v-on:input="updateValue($event.target.checked)" />
            <span class="slider round"></span>
        </label>
    </div>
</template>

<script>
export default {
    name: 'Switcher',
    props: { value: { type: undefined, default: false } },
    data() {
        return {}
    },
    methods: {
        updateValue: function (value) {
            this.$emit('input', value)
        }
    }
}
</script>

<style lang="scss">
.switch-container {
    .switcher {
        position: relative;
        display: inline-block;
        width: 45px;
        height: 25px;

        input {
            opacity: 0;
            width: 0;
            height: 0;
        }
        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            -webkit-transition: 0.4s;
            transition: 0.4s;

            &:before {
                position: absolute;
                content: '';
                height: 22px;
                width: 22px;
                left: 2px;
                bottom: 2px;
                background-color: white;
                -webkit-transition: 0.4s;
                transition: 0.4s;
            }
        }
        input:checked + .slider {
            background-color: #2196f3;
        }

        input:focus + .slider {
            box-shadow: 0 0 1px #2196f3;
        }

        input:checked + .slider:before {
            -webkit-transform: translateX(19px);
            -ms-transform: translateX(19px);
            transform: translateX(19px);
        }

        /* Rounded sliders */
        .slider.round {
            border-radius: 30px;
        }

        .slider.round:before {
            border-radius: 50%;
        }
    }
}
</style>
